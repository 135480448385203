import {Modal} from '@mantine/core';
import {BasicButton} from '@packages/ui';
import {type FC} from 'react';
import {LOGIN} from '../../config/constants/routes';
import {getMailAuthLinkWithParamsHooks} from '../services/getMailAuthLinkWithParamsHooks';

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const NoLoginAlertModal: FC<Props> = (props) => {
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title=""
      size="md"
      padding="md"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="text-center">
        <p className="mb-[20px]">
          会員限定機能です。
          アカウントをお持ちでない方は新規会員登録を行ってください。
        </p>
      </div>
      <div className="flex justify-around">
        <BasicButton
          label="ログイン"
          isPrimary={true}
          className="mx-8"
          onClick={() => {
            location.href = LOGIN;
          }}
        />
        <BasicButton
          label="新規登録"
          isPrimary={false}
          className="mx-12"
          onClick={() => {
            location.href = mailAuthLink;
          }}
        />
      </div>
    </Modal>
  );
};
